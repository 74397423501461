<template>
  <div class="property-type-edit animatedBox">
    <div class="container fluid">
      <form @submit.prevent="submitForm">
        <div class="card mb-2 pb-1">
          <h4 class="mainTitle lineBottom">Edit Property Type</h4>
          <div class="main-contents">
            <div class="input-wrapper mt-2 px-0">
              <fd-input
                type="text"
                :label="`Name`"
                v-model="propertyType.name"
                :required="true"
              />
            </div>
            <div class="input-wrapper mt-2 px-0">
              <fd-input
                type="number"
                :label="`Interest Rate`"
                v-model="propertyType.interestRate"
                :required="true"
                pattern="^\d{1,1}[\.]{1,1}[\d]{1,2}$"
                :placeholder="`Must be a decimal value. (Eg: 0.12)`"
              />
            </div>
            <div class="input-wrapper mt-2 px-0">
              <fd-input
                type="number"
                :label="`Down Payment Rate`"
                v-model="propertyType.downPaymentRate"
                :required="true"
                pattern="^\d{1,1}[\.]{1,1}[\d]{1,2}$"
                :placeholder="`Must be a decimal value. (Eg: 0.12)`"
              />
            </div>
            <div class="input-wrapper mt-2 px-0">
              <fd-input
                type="text"
                :label="`Term`"
                v-model="propertyType.term"
                :required="true"
              />
            </div>
            <div class="input-wrapper mt-2 px-0">
              <fd-input
                type="text"
                :label="`Code`"
                v-model="propertyType.code"
                :required="true"
              />
            </div>
            <div class="input-wrapper mt-2 px-0">
              <fd-input
                type="text"
                :label="`Description`"
                v-model="propertyType.description"
                :required="true"
              />
            </div>
            <div class="input-wrapper mt-2 px-0">
              <fd-select
                :label="'Property Category'"
                v-model="propertyType.propertyCategory"
                :required="true"
                :options="propertyCategoryOptions"
              >
              </fd-select>
            </div>
          </div>
          <div class="text-right px-2 mt-4 mb-2">
            <button type="submit" class="btn main">Update</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import propertyCategoriesEnums from "@/enums/propertyCategory";
import { PropertyTypeModel } from "@/models";

export default {
  name: "property-type-edit",
  data() {
    return {
      propertyCategoryOptions: this.$mapJsonToArray(
        propertyCategoriesEnums,
        (e) => {
          return {
            id: e,
            name: e
          };
        }
      ),
      propertyType: {
        name: "",
        interestRate: "",
        downPaymentRate: "",
        term: "",
        code: "",
        description: "",
        propertyCategory: ""
      }
    };
  },
  computed: {},
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      this.$store.commit("setIsLoading", true);
      try {
        let data = await this.$store.dispatch(
          "managePropertyTypes/getPropertyType",
          this.$route.params.id
        );

        this.propertyType = PropertyTypeModel.getPropertyTypeDetailResponse(
          this._.cloneDeep(data)
        );
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        throw error;
      }
    },
    async submitForm() {
      console.log(this.propertyType);
      this.$store.commit("setIsLoading", true, { root: true });
      try {
        const request = await this.$store.dispatch(
          "managePropertyTypes/updatePropertyTypes",
          {
            id: this.$route.params.id,
            content: PropertyTypeModel.postPropertyTypeDetailPayload(
              this.propertyType
            )
          }
        );

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Property type has been updated successfully."
        });
        this.$router.push({ name: "ManagePropertyTypes" });
        this.$store.commit("setIsLoading", false, { root: true });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false, { root: true });
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        console.log(error);
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.property-type-edit {
  form {
    @extend %formDesign;
  }
}
</style>